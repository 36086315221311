import styled, { css } from 'styled-components';

export default styled.div`
  max-width: 100%;
  color: inherit;

  h1, h2, h3, h4, h5, h6 {
    margin-top: 2em;

    &:first-of-type {
      margin-top: 0;
    }
  }

  p {
    font-size: ${({ matchFontSize }) => (matchFontSize ? '1rem' : '0.875rem')};
    line-height: 2;
    ${({ compact }) => compact && css`
      line-height: 1.5;
      i {
        font-size: 0.85rem;
      }
    `}
  }

  ul {
    li {
      font-size: ${({ matchFontSize }) => (matchFontSize ? '1rem' : '0.875rem')};
      line-height: 2;
    }
  }

  ${({ flexList }) => flexList && css`
  ul {
    display: flex;
    width: 24rem;
    max-width: 100%;
    flex-direction: column;
    padding: 0;
    margin-top: 1rem;
    list-style-type: none;

    li {
      display: flex;
      justify-content: space-between;
      font-size: 1rem;
      line-height: 1.5;
    }
  }
  `}

  ${({ inline }) => inline && css`
    display: inline;
  `}
`;
