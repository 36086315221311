/* eslint-disable */
import React, { Fragment, memo, useState } from 'react';
import { Mutation, compose } from 'react-apollo';
import PropTypes from 'prop-types';

import { appendQuery } from '../../../lib/location';
import { deleteCookie } from '../../../lib/cookies';

import Navigation from './Navigation';

import {
  Header as HeaderStyled,
  A,
  Button,
  Div,
  Icon,
  Img,
  Span
} from './Layout.style';

import svgBurgerMenu from '../../../static/images/icons/BurgerMenu.svg';
import svgCloseMenu from '../../../static/images/icons/Close.svg';

import LOG_OUT from '../graphql/logOut.gql';
import { navigateTo } from '../../../lib/helpers/navigation';

function handleReturnUrl(event) {
  event.preventDefault();

  const redirectUrl = window.location.href;

  const href = appendQuery(event.target.href, {
    ReturnUrl: encodeURIComponent(redirectUrl),
    UseReturnURL: 'True'
  });

  navigateTo(href);

  return event;
}

const handleClientLogout = event => {
  event.preventDefault();

  // Delete client cookies
  deleteCookie('access_token');
  deleteCookie('PersonToken');

  return event;
};

export const handleLogOutAndReturnURL = logOut => event => {
  event.preventDefault();
  event.persist();

  return logOut({
    variables: {
      input: {}
    }
  }).then(() =>
    compose(
      handleReturnUrl,
      handleClientLogout
    )(event)
  );
}

function Header({ headerConfiguration, headerLinks, headerMenus, isLoggedIn }) {
  const { logoUrl, logoUrlLink } = headerConfiguration ?? {};
  const [isNavigationOpen, setNavigationOpen] = useState(false);
  const filteredLinks = isLoggedIn
    ? headerLinks.filter(({ showWhenLoggedIn }) => showWhenLoggedIn)
    : headerLinks.filter(({ showWhenLoggedOut }) => showWhenLoggedOut);

  const navigationLinks = filteredLinks.filter(({ showButton }) => !showButton);

  function handleNavigation() {
    setNavigationOpen(!isNavigationOpen);
  }

  return (
    <HeaderStyled className="header" id="header">
      <Div className="header__inner">
        <Div className="header__grid grid-container">
          <div className="header__x grid-x grid-margin-x align--middle">
            <div className="header__branding large-shrink cell">
              <div className="grid-x align--middle">
                <div className="header__logo auto cell">
                  <A href={logoUrlLink ? logoUrlLink : '/'}>
                    <Img
                      src={logoUrl}
                      className="header__logo-img"
                      alt="The Caravan Club"
                    />
                  </A>
                </div>
                <div className="header__mobile__menu shrink cell hide-for-large">
                  <Button type="button" className={`header__btn header__btn--menu icon-button${isNavigationOpen ? ' header__btn--menu--open' : '' }`} data-menu="" onClick={handleNavigation}>
                    <Icon
                      className="icon icon--menu"
                      icon={svgBurgerMenu}
                      size="18px"
                    />
                    <Icon
                      className="icon icon--close"
                      icon={svgCloseMenu}
                      size="13px"
                    />
                    <Span className="vh">Menu</Span>
                  </Button>
                </div>
              </div>
            </div>

            <Div className="header__options auto cell">
              <Mutation mutation={LOG_OUT}>
                {logOut => {
                  return (
                    <Div className="header__links type--small">
                      {filteredLinks.map(link => {
                        let onClick = link.appendRedirectQuery
                          ? handleReturnUrl
                          : null;

                        if (link.logOutBeforeRedirect) {
                          onClick = handleLogOutAndReturnURL(logOut);
                        }

                        return (
                          <Fragment key={link.href}>
                            {!link.showButton ? (
                              <A
                                className="header__link"
                                href={link.href}
                                onClick={onClick}
                                target={link.target}
                                title={link.title}
                                key={link.href}
                              >
                                {link.text}
                              </A>
                            ) : (
                              <A
                                className="btn btn--primary show-for-large"
                                href={link.href}
                                onClick={onClick}
                                target={link.target}
                                title={link.title}
                                key={link.href}
                              >
                                {link.text}
                              </A>
                            )}

                          </Fragment>
                        );
                      })}
                    </Div>
                  );
                }}
              </Mutation>
            </Div>
          </div>
        </Div>
      </Div>
      <Navigation
        links={navigationLinks}
        menus={headerMenus}
        onToggleOpen={handleNavigation}
        isOpen={isNavigationOpen}
      />
    </HeaderStyled>
  );
}

Header.propTypes = {
  headerConfiguration: PropTypes.shape({
    logoUrl: PropTypes.string,
    logoUrlLink: PropTypes.string,
  }).isRequired,
  headerLinks: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  headerMenus: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default memo(Header);
