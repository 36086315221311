import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';

import withDictionary from '../../../hocs/withDictionary';
import { POST_TYPES, navigateTo } from '../../../lib/helpers/navigation';

import RichTextStyled from './RichText.style';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  content: PropTypes.string,
  compact: PropTypes.bool,
  flexList: PropTypes.bool,
  matchFontSize: PropTypes.bool,
  inline: PropTypes.bool,
};

const defaultProps = {
  className: '',
  content: '',
  compact: false,
  flexList: false,
  matchFontSize: false,
  inline: false,
};

function RichText({
  children, className, content, compact, flexList, matchFontSize, inline,
}) {
  const onLinkClick = (event) => {
    if (event?.target?.href) {
      event.preventDefault();
      navigateTo(event.target.href, { type: POST_TYPES.NEW_WINDOW });
    }
  };

  return <RichTextStyled
    className={className}
    compact={compact}
    flexList={flexList}
    matchFontSize={matchFontSize}
    onClick={onLinkClick}
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content || children) }}
    inline={inline}
  />;
}

RichText.propTypes = propTypes;
RichText.defaultProps = defaultProps;

export default withDictionary(RichText, { name: 'RichText' });
