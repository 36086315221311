import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import { StyleWrapper } from './Layout.style';

function Layout({ isLoggedIn, children, config }) {
  return (
    <>
      <StyleWrapper styles={config.css}>
        <Header {...config.header} isLoggedIn={isLoggedIn} />
      </StyleWrapper>
      {children}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.shape({
    css: PropTypes.string,
    header: PropTypes.shape({}),
  }).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default memo(Layout);
