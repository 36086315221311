export default {
  BELOW_HEADER: 98,
  HEADER: 99, // specified in Layout.config
  BOOKING_BASKET_SHEET: 100,
  TAB_CONTENT_STICKY_BECOME_A_MEMBER: 1,
  TAB_CONTENT_CAMPSITE_SEARCH_WRAPPER: 2,
  HEADER_OVERRIDE: 'auto',
  IMPORTANT_CONTENT: 9999,
  POPOVER: 99999,
  BASKET_WRAPPER: 9999999,
  MAIN_TOP_GRADIENT: 10000000,
  BASKET_WRAPPER_OPEN: 10000001, // above gradient
  MOBILE_NAV: 10000002,
  MODAL: 99999999,
};
